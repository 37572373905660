<template>
  <div id="flightBooking" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Transport</div>
        <v-btn class="theme-btn-even" @click="dialogCreateTransport = true" v-show="userdata.r_id == 2 || userdata.r_id == 8">
          <span class="I-create"></span>
          <span>Create Transport</span>
        </v-btn>
      </div>
    </div>
    <div id="calendarDATA" class="wrap-Main">
      <div class="box-S2">
        <CompListFlight
          ref="CompListFlight"
          :DataDateFromCalendar="datadatefromcalender"
          :Service="'transportselect'"
        ></CompListFlight>
      </div>
      <div class="box-S2">
        <calendar
          :Service="'transportselect'"
          @DataCalender="BindingDataFromCalendar"
        ></calendar>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogCreateTransport" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-24 TF">Create Transport</div>
          </div>
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-select
                  :items="itemsListCustomer"
                  v-model="customer"
                  label="Customer"
                  item-text="o_name"
                  item-value="o_id"
                  return-object
                ></v-select>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S2">
                <v-menu
                  v-model="menuselectdate"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="hideMessage input-date"
                      v-model="date"
                      label="Select Date"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-range"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menuselectdate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="box-S2">
                <v-text-field
                  append-icon="mdi-clock-outline"
                  v-model="time"
                  label="Time"
                  type="time"
                ></v-text-field>
              </div>
            </div>


            <!-- <div class="b-row">
              <div class="box-S4">
                <v-select
                  :items="itemsListVehicle"
                  v-model="vehicleOut"
                  label="Vehicle Outbound"
                  item-text="v_license_plate"
                  item-value="v_id"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-select
                  :items="itemsListDriver"
                  v-model="driverOut"
                  label="Driver Outbound"
                  item-text="d_name"
                  item-value="d_id"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-select
                  :items="itemsListVehicle"
                  v-model="vehicleIn"
                  label="Vehicle Inbound"
                  item-text="v_license_plate"
                  item-value="v_id"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-select
                  :items="itemsListDriver"
                  v-model="driverIn"
                  label="Driver Inbound"
                  item-text="d_name"
                  item-value="d_id"
                  return-object
                ></v-select>
              </div>
            </div> -->


            <div class="box-btn">
              <v-btn
                class="theme-btn-even btn-cancel Bsize100"
                text
                @click="dialogCreateTransport = false"
              >
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn
                :disabled="buttonSave"
                class="theme-btn-even btn-save Bsize100"
                text
                @click="GotoCreateTransport()"
              >
                <span class="T-size-18">save</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import feathersClientVan from "../plugins/feathers-client";

import calendar from "../components/main/Calendar";
import CompListFlight from "../components/main/CompList";
import { startOfDay, endOfDay, format } from "date-fns";

var today = new Date();
export default {
  data: () => ({
    datadatefromcalender: new Date().toISOString().substr(0, 10),
    userdata: "",

    dialogCreateTransport: false,
    menuselectdate: false,

    customer: "",
    itemsListCustomer: [],

    date: format(new Date(), "yyyy-MM-dd"),
    time: "00:00",

    vehicleOut: "",
    vehicleIn: "",
    itemsListVehicle: [],

    driverOut: "",
    driverIn: "",
    itemsListDriver: [],

    buttonSave: false,
  }),
  components: {
    calendar,
    CompListFlight,
  },
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    await this.SelectListCustomer();
    //await this.SelectListVehicle();
    // await this.SelectListDriver();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async BindingDataFromCalendar(dataDate) {
      this.datadatefromcalender = dataDate;
      await this.$refs.CompListFlight.renderListFlight(dataDate);
    },

    async SelectListCustomer() {
      try {
        var q = {};
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data;
        this.customer = this.itemsListCustomer[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้ (1)");
      }
    },

    async SelectListVehicle() {
      try {
        var q = {};
        q.retire = 0;
        var res = await feathersClientVan
          .service("vehicle")
          .find({ query: q });
        this.itemsListVehicle = res.data;
        this.vehicleOut = this.itemsListVehicle[0];
        this.vehicleIn = this.itemsListVehicle[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้ (2)");
      }
    },

    async SelectListDriver() {
      try {
        var q = {};
        q.retire = 0;
        var res = await feathersClientVan
          .service("driver")
          .find({ query: q });
        this.itemsListDriver = res.data;
        this.driverOut = this.itemsListDriver[0];
        this.driverIn = this.itemsListDriver[0];
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้ (3)");
      }
    },

    async GotoCreateTransport() {
      this.buttonSave = true;
      try {
        var DataAdd = {
          o_id: this.customer.o_id,
          ts_date: this.date + " " + this.time,

          // //Add
          // v_id_out: this.vehicleOut.v_id,
          // v_license_plate_out: this.vehicleOut.v_license_plate,
          // d_id_out: this.driverOut.d_id,
          // d_name_out: this.driverOut.d_name,

          // //Add
          // v_id_in: this.vehicleIn.v_id,
          // v_license_plate_in: this.vehicleIn.v_license_plate,
          // d_id_in: this.driverIn.d_id,
          // d_name_in: this.driverIn.d_name,
        };
        var res = await feathersClientUOA.service("transport").create(DataAdd);
        var url = "#/transportdetail/Edit/" + res.ts_id;
        window.location.replace(url);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
</style>